/* Style the header with a grey background and some padding */
.header-container {
  overflow: hidden;
  background-color: #f1f1f1;
}

.logo-img {
  height: 100px;
  width:100px;
}

.hidden {
  display: none;
}

/* Style the header links */
a.header {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active/current link*/
.header-container a.active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  font-family: 'Stardos Stencil', cursive;
}

.main {
  margin:auto;
  font-family: 'Stardos Stencil', cursive;
  padding: 0 15%;
}

.contact {
  text-align: center;
  font-family: 'Stardos Stencil', cursive;
  padding: 0 40%;
}

.contact-buttons {
  padding: 0 20%;
}

.contact-logo-img {
  width: 30%;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}
